
/* eslint-disable eqeqeq */
import { Button } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import { defaultInputSmStyle, IconStyle } from '../../commen/defaultStyles';
import ClientPlusIcon from '../../Icons/ClientPlusIcon';
import EmailIcon from '../../Icons/EmailIcon';
import MobileIcon from '../../Icons/MobileIcon';
import PlusCircleIcon from '../../Icons/PlusCircleIcon';
import ClientModel from '../../models/ClientModel';

function ClientDetails({
    handleChange, formData, setFormData, clientErrors,
    invoiceDate, setInvoiceDate, billModules, addValue, dueDate, serviceBillNo,
    setDueDate, handleAddvalue, handleRemoveDate

}: any) {
    const [addclientMobile, setAddClientMobile] = useState(false);
    const [addClientPan, setAddClientPan] = useState(false);
    const [addClientGst, setAddClientGst] = useState(false);
    const [addClientEmail, setAddClientEmail] = useState(false);
    const [shippmentCheckBox, setShippmentCheckBox] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [existingData, setExistingData] = useState(null as any);

    const handleConfirm = () => {
        setIsModalOpen(true)
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const clientMobile = (value: any) => {
        setAddClientMobile(!addclientMobile);
        if (value == "addtTrueClientno") {
            setFormData({ ...formData, clientMobile: "", isclientMobile: true, errors: { ...formData.errors, clientMobileErrorMessage: '' } })
        }
    };
    const handleClientAddPan = (value: any) => {
        setAddClientPan(!addClientPan);
        if (value == "addTrueClientPanNo") {
            setFormData({ ...formData, clientPan: "", isClientPan: true, errors: { ...formData.errors, clientPanErrorMessage: '' } })
        }
    };

    const handleClientAddEmail = (value: any) => {
        setAddClientEmail(!addClientEmail);
        if (value == "addTrueClientEmail") {
            setFormData({ ...formData, clientEmail: "", isClientEmail: true, errors: { ...formData.errors, clientEmailErrorMessage: '' } })
        }
    };

    const handleClientAddGst = (value: any) => {
        setAddClientGst(!addClientGst);
        if (value == "addTrueClientGst") {
            setFormData({ ...formData, clientgst: "", isClientGst: true, errors: { ...formData.errors, clientgstErrorMessage: '' } })
        }
    };

    const handleSelect = (data: any) => {
        setExistingData(data)
        clientErrors.clientName = null;
        setFormData({
            ...formData,
            clientName: data.client_name,
            clientAddress: data.client_address,
            clientCity: data.city,
            clientState: data.state,
            clientPostalCode: data.postal_code,
            clientMobile: data.client_mobile,
            clientEmail: data.client_email,
            clientPan: data.pan_no,
            clientgst: data.gst,
            clientId: data._id?.$oid,
            isClientGst: true,
            isClientPan: true,
            isClientEmail: true,
            isclientPostalCode: true,
            isclientMobile: true,
            errors: { ...formData.errors, clientgstErrorMessage: '', clientEmailErrorMessage: '', clientPanErrorMessage: '', clientMobileErrorMessage: '', clientPostalCodeErrorMessage: "" }
        });

        setIsModalOpen(false);
        setAddClientEmail(Boolean(data.client_email))
        setAddClientMobile(Boolean(data.client_mobile))
        setAddClientPan(Boolean(data.pan_no))
        setAddClientGst(Boolean(data.gst))

    };

    useEffect(() => {
        formData.clientEmail && setAddClientEmail(true)
        formData.clientMobile && setAddClientMobile(true)
        formData.clientPan && setAddClientPan(true)
        formData.clientgst && setAddClientGst(true)
        formData.shipToName && setShippmentCheckBox(true)
        formData.shipToAddress && setShippmentCheckBox(true)
    }, [formData.clientEmail, formData.clientMobile, formData.clientPan, formData.clientgst, formData.shipToAddress, formData.shipToName])

    const CustomInputWithIcon = ({ value, onClick }: any) => (
        <div className="relative ml-1 sm:ml-auto">
            <input
                type="text"
                value={value}
                readOnly
                className={
                    defaultInputSmStyle +
                    "text-center  w-[130px] sm:w-[156px] border border-[#008065] border-[2px] font-poppins font-medium pl-3 sm:pl-9 pr-5"
                }
            />
            <FaCalendarAlt
                className="absolute right-4 top-0 mt-3 mr-2 cursor-pointer"
                onClick={onClick}
            />
        </div>
    );

    const CustomDueDate = ({ value, onClick }: any) => (
        <div className="relative ml-5 sm:ml-auto dueUniqDate">
            <input
                type="text"
                value={value}
                readOnly
                className={
                    defaultInputSmStyle +
                    "text-center  w-[130px] sm:w-[156px]  border border-[#008065] border-[2px] font-poppins font-medium pl-3 sm:pl-9 pr-5"
                }
            />
            <FaCalendarAlt
                className="absolute right-4 top-0 mt-3 mr-2 cursor-pointer"
                onClick={onClick}
            />
        </div>
    );
    //     return (existingData || params.id) ? (
    //         <div className="  flex-1 md:mx-2 mt-2 md:mt-[2px] ">
    //             <div className="flex flex-col md:flex-row justify-between items-left">
    //                 <div
    //                     className="flex flex-1 items-center whitespace-nowrap justify-start mb-4"
    //                     style={{
    //                         color: "#008065",
    //                         fontFamily: "Poppins",
    //                         fontSize: "14px",
    //                         fontWeight: 700,
    //                     }}
    //                 >
    //                     Client Details (Billed To)
    //                 </div>

    //                 <div className="flex flex-1 items-center justify-center md:justify-end ">
    //                     <ClientPlusIcon className="w-4 h-4" />
    //                     <Button onClick={handleConfirm}>
    //                         <span
    //                             style={{
    //                                 color: "#008065",
    //                                 fontFamily: "Poppins",
    //                                 fontSize: "14px",
    //                                 fontWeight: 700,
    //                             }}
    //                             className="text-[#008065] ml-1"
    //                         >
    //                             EXISTING
    //                         </span>
    //                     </Button>
    //                 </div>


    //                 {isModalOpen && (
    //                     <ClientModel closeModal={closeModal} handleSelect={handleSelect} />
    //                 )}
    //             </div>



    //             <div className="flex flex-row justify-between items-center mb-2">
    //                 <div
    //                     className="flex-1  required"
    //                     style={{
    //                         fontFamily: "poppins",
    //                         fontSize: "14px",
    //                         fontWeight: 700,
    //                     }}
    //                 >
    //                     Name
    //                 </div>
    //                 <div className="font-title flex-1 text-left">

    //                     <input
    //                         autoComplete="nope"
    //                         className={"focus:outline-none rounded-[7px]  h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
    //                         style={{
    //                             fontFamily: "poppins",
    //                             fontSize: "14px",
    //                             fontWeight: 400,
    //                         }}
    //                         name='clientName'
    //                         value={formData.clientName}
    //                         readOnly
    //                     />
    //                 </div>
    //             </div>

    //             <div className="flex flex-row justify-between items-center mb-2">
    //                 <div
    //                     className=" flex-1 mb-2"
    //                     style={{
    //                         fontFamily: "poppins",
    //                         fontSize: "14px",
    //                         fontWeight: 700,
    //                     }}
    //                 >

    //                     Address
    //                 </div>
    //                 <div className="font-title flex-1 text-left">
    //                     <input
    //                         autoComplete="nope"
    //                         className={" focus:outline-none rounded-[7px]  h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
    //                         style={{
    //                             fontFamily: "poppins",
    //                             fontSize: "14px",
    //                             fontWeight: 400,
    //                         }}
    //                         name='clientAddress'
    //                         value={formData.clientAddress}
    //                         readOnly
    //                     />

    //                 </div>
    //             </div>

    //             <div className="flex flex-row justify-between items-center mb-2">
    //                 <div
    //                     className="flex-1"
    //                     style={{
    //                         fontFamily: "poppins",
    //                         fontSize: "14px",
    //                         fontWeight: 700,
    //                     }}
    //                 >
    //                     City
    //                 </div>
    //                 <div className="font-title flex-1 text-left ">

    //                     <input
    //                         autoComplete="nope"
    //                         className={" rounded-[7px]  h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
    //                         style={{
    //                             fontFamily: "poppins",
    //                             fontSize: "14px",
    //                             fontWeight: 400,
    //                         }}
    //                         name='clientCity'
    //                         value={formData.clientCity}
    //                         readOnly
    //                     />
    //                 </div>
    //             </div>

    //             <div className="flex flex-row justify-between items-center mb-2">
    //                 <div
    //                     className="flex-1"
    //                     style={{
    //                         fontFamily: "poppins",
    //                         fontSize: "14px",
    //                         fontWeight: 700,
    //                     }}
    //                 >
    //                     State
    //                 </div>
    //                 <div className="font-title flex-1 text-left ">

    //                     <input
    //                         autoComplete="nope"
    //                         className={"focus:outline-none rounded-[7px]  h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
    //                         style={{
    //                             fontFamily: "poppins",
    //                             fontSize: "14px",
    //                             fontWeight: 400,
    //                         }}
    //                         name='clientState'
    //                         value={formData.clientState}
    //                         readOnly
    //                     />
    //                 </div>
    //             </div>

    //             <div className="flex flex-row justify-between items-center mb-2">
    //                 <div
    //                     className="flex-1"
    //                     style={{
    //                         fontFamily: "poppins",
    //                         fontSize: "14px",
    //                         fontWeight: 700,
    //                     }}
    //                 >
    //                     Postal Code
    //                 </div>
    //                 <div className="font-title flex-1 text-left ">
    //                     <input
    //                         type=""
    //                         autoComplete="nope"
    //                         className={" focus:outline-none rounded-[7px]  h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
    //                         style={{
    //                             fontFamily: "poppins",
    //                             fontSize: "14px",
    //                             fontWeight: 400,

    //                         }}
    //                         maxLength={6}
    //                         name='clientPostalCode'
    //                         value={formData.clientPostalCode}
    //                     />

    //                 </div>
    //             </div>

    //             <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
    //                 {addclientMobile ? (
    //                     <>
    //                         <div className="font-title  text-left ">
    //                             <div
    //                                 className=" flex-1 required"
    //                                 style={{
    //                                     fontFamily: "poppins",
    //                                     fontSize: "14px",
    //                                     fontWeight: 700,
    //                                 }}
    //                             >
    //                                 Mobile
    //                             </div>
    //                             <input
    //                                 readOnly
    //                                 type="text"
    //                                 autoComplete="nope"
    //                                 className={"focus:outline-none rounded-[7px]  w-full"}
    //                                 style={{
    //                                     fontFamily: "poppins",
    //                                     fontSize: "14px",
    //                                     fontWeight: 400,
    //                                 }}
    //                                 maxLength={10}
    //                                 name="clientMobile"
    //                                 value={formData.clientMobile}

    //                             />
    //                         </div>
    //                     </>
    //                 ) : (
    //                     <>
    //                     </>
    //                 )}


    //                 <div className="flex mb-2">
    //                     {addClientEmail ? (
    //                         <>
    //                             <div className="font-title  text-left">
    //                                 <div
    //                                     className=" flex-1 required"
    //                                     style={{
    //                                         fontFamily: "poppins",
    //                                         fontSize: "14px",
    //                                         fontWeight: 700,
    //                                     }}
    //                                 >
    //                                     Email
    //                                 </div>
    //                                 <input
    //                                     readOnly
    //                                     autoComplete="nope"
    //                                     className={" focus:outline-none rounded-[7px] w-full"}
    //                                     style={{
    //                                         fontFamily: "poppins",
    //                                         fontSize: "14px",
    //                                         fontWeight: 400,
    //                                     }}
    //                                     name='clientEmail'
    //                                     value={formData.clientEmail}
    //                                 />
    //                             </div>
    //                         </>
    //                     ) : (
    //                         <>
    //                         </>
    //                     )}
    //                 </div>

    //                 <div className="flex mb-2">
    //                     {addClientPan ? (
    //                         <>
    //                             <div className="font-title text-left ">
    //                                 <div
    //                                     className=" flex-1 required"
    //                                     style={{
    //                                         fontFamily: "poppins",
    //                                         fontSize: "14px",
    //                                         fontWeight: 700,
    //                                     }}
    //                                 >
    //                                     Pan Number
    //                                 </div>
    //                                 <input
    //                                     readOnly
    //                                     autoComplete="nope"
    //                                     className={"focus:outline-none rounded-[7px] w-full"}
    //                                     style={{
    //                                         fontFamily: "poppins",
    //                                         fontSize: "14px",
    //                                         fontWeight: 400,
    //                                     }}
    //                                     name='clientPan'
    //                                     value={formData.clientPan}
    //                                 />
    //                             </div>
    //                         </>
    //                     ) : (
    //                         <>
    //                         </>
    //                     )}
    //                 </div>
    //                 <div className="flex mb-2">
    //                     {addClientGst ? (
    //                         <>
    //                             <div className="font-title  text-left ">
    //                                 <div
    //                                     className=" flex-1 required"
    //                                     style={{
    //                                         fontFamily: "poppins",
    //                                         fontSize: "14px",
    //                                         fontWeight: 700,
    //                                     }}
    //                                 >
    //                                     Gst
    //                                 </div>
    //                                 <input
    //                                     readOnly
    //                                     autoComplete="nope"
    //                                     className={" focus:outline-none rounded-[7px] w-full"}
    //                                     style={{
    //                                         fontFamily: "poppins",
    //                                         fontSize: "14px",
    //                                         fontWeight: 400,
    //                                     }}
    //                                     name='clientgst'
    //                                     value={formData.clientgst}
    //                                     onChange={(e) => handleChange(e)}
    //                                 />
    //                             </div>
    //                         </>
    //                     ) : (
    //                         <>
    //                         </>
    //                     )}
    //                 </div>
    //             </div>

    //         </div >
    //     ) : (
    //         <>
    //             <div className="  flex-1 md:mx-2 mt-2 md:mt-[2px] ">
    //                 <div className="flex flex-col md:flex-row justify-between items-left">
    //                     <div
    //                         className="flex flex-1 items-center whitespace-nowrap justify-start"
    //                         style={{
    //                             color: "#008065",
    //                             fontFamily: "Poppins",
    //                             fontSize: "14px",
    //                             fontWeight: 700,
    //                         }}
    //                     >
    //                         Client Details (Billed To)
    //                     </div>

    //                     <div className="flex flex-1 items-center justify-center md:justify-end ">
    //                         <ClientPlusIcon className="w-4 h-4" />
    //                         <Button onClick={handleConfirm}>
    //                             <span
    //                                 style={{
    //                                     color: "#008065",
    //                                     fontFamily: "Poppins",
    //                                     fontSize: "14px",
    //                                     fontWeight: 700,
    //                                 }}
    //                                 className="text-[#008065] ml-1"
    //                             >
    //                                 EXISTING
    //                             </span>
    //                         </Button>
    //                     </div>


    //                     {isModalOpen && (
    //                         <ClientModel closeModal={closeModal} handleSelect={handleSelect} />
    //                     )}
    //                 </div>



    //                 <div className="flex flex-row justify-between items-center mb-2">
    //                     <div
    //                         className="flex-1  required"
    //                         style={{
    //                             fontFamily: "poppins",
    //                             fontSize: "14px",
    //                             fontWeight: 700,
    //                         }}
    //                     >
    //                         <span>Name</span>
    //                     </div>
    //                     <div className="font-title flex-1 text-left">

    //                         <input
    //                             autoComplete="nope"
    //                             placeholder="Client Name"
    //                             className={"border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
    //                             style={{
    //                                 fontFamily: "poppins",
    //                                 fontSize: "14px",
    //                                 fontWeight: 400,
    //                                 borderColor: clientErrors.clientName ? "red" : "#008065",
    //                             }}
    //                             name='clientName'
    //                             value={formData.clientName}
    //                             onChange={(e) => handleChange(e)}
    //                         />
    //                         {clientErrors.clientName && (
    //                             <p
    //                                 style={{
    //                                     fontFamily: "poppins",
    //                                     fontWeight: 700,
    //                                     color: "#ff0000",
    //                                 }}
    //                                 className="text-red-400 text-xs"
    //                             >
    //                                 {clientErrors.clientName}
    //                             </p>
    //                         )}

    //                     </div>
    //                 </div>

    //                 <div className="flex flex-row justify-between items-center mb-2">
    //                     <div
    //                         className=" flex-1 mb-2"
    //                         style={{
    //                             fontFamily: "poppins",
    //                             fontSize: "14px",
    //                             fontWeight: 700,
    //                         }}
    //                     >
    //                         
    //                         Address
    //                     </div>
    //                     <div className="font-title flex-1 text-left">
    //                         <input
    //                             autoComplete="nope"
    //                             placeholder="Address (Optional)"
    //                             className={"border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
    //                             style={{
    //                                 fontFamily: "poppins",
    //                                 fontSize: "14px",
    //                                 fontWeight: 400,
    //                                 borderColor: "#008065",
    //                             }}
    //                             name='clientAddress'
    //                             value={formData.clientAddress}
    //                             onChange={(e) => handleChange(e)}
    //                         />

    //                     </div>
    //                 </div>

    //                 <div className="flex flex-row justify-between items-center mb-2">
    //                     <div
    //                         className="flex-1"
    //                         style={{
    //                             fontFamily: "poppins",
    //                             fontSize: "14px",
    //                             fontWeight: 700,
    //                         }}
    //                     >
    //                         City
    //                     </div>
    //                     <div className="font-title flex-1 text-left ">

    //                         <input
    //                             autoComplete="nope"
    //                             placeholder="City (Optional)"
    //                             className={"border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
    //                             style={{
    //                                 fontFamily: "poppins",
    //                                 fontSize: "14px",
    //                                 fontWeight: 400,
    //                                 borderColor: "#008065",
    //                             }}
    //                             name='clientCity'
    //                             value={formData.clientCity}
    //                             onChange={(e) => handleChange(e)}
    //                         />


    //                     </div>
    //                 </div>

    //                 <div className="flex flex-row justify-between items-center mb-2">
    //                     <div
    //                         className="flex-1"
    //                         style={{
    //                             fontFamily: "poppins",
    //                             fontSize: "14px",
    //                             fontWeight: 700,
    //                         }}
    //                     >
    //                         State
    //                     </div>
    //                     <div className="font-title flex-1 text-left ">

    //                         <input
    //                             autoComplete="nope"
    //                             placeholder="State (Optional)"
    //                             className={"border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
    //                             style={{
    //                                 fontFamily: "poppins",
    //                                 fontSize: "14px",
    //                                 fontWeight: 400,
    //                                 borderColor: "#008065",
    //                             }}
    //                             name='clientState'
    //                             value={formData.clientState}
    //                             onChange={(e) => handleChange(e)}
    //                         />


    //                     </div>
    //                 </div>

    //                 <div className="flex flex-row justify-between items-center mb-2">
    //                     <div
    //                         className="flex-1"
    //                         style={{
    //                             fontFamily: "poppins",
    //                             fontSize: "14px",
    //                             fontWeight: 700,
    //                         }}
    //                     >
    //                         Postal Code
    //                     </div>
    //                     <div className="font-title flex-1 text-left ">
    //                         <input
    //                             type=""
    //                             autoComplete="nope"
    //                             placeholder="Postalcode (Optional)"
    //                             className={"border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
    //                             style={{
    //                                 fontFamily: "poppins",
    //                                 fontSize: "14px",
    //                                 fontWeight: 400,
    //                                 borderColor: formData.isclientPostalCode
    //                                     ? "#008065"
    //                                     : "#FF0000",
    //                             }}
    //                             maxLength={6}
    //                             name='clientPostalCode'
    //                             value={formData.clientPostalCode}
    //                             onChange={(e) => {
    //                                 const re = /^[0-9\b]+$/;

    //                                 if (re.test(e.target.value) || (e.target.value == "")) {
    //                                     handleChange(e)
    //                                 }

    //                             }}
    //                         />
    //                         {formData.errors?.clientPostalCodeErrorMessage && (
    //                             <span
    //                                 style={{
    //                                     fontFamily: "poppins",
    //                                     fontWeight: 700,
    //                                     color: "#ff0000",
    //                                 }}
    //                                 className="text-red-400 text-xs"
    //                             >
    //                                 {formData.errors.clientPostalCodeErrorMessage}
    //                             </span>
    //                         )}
    //                     </div>
    //                 </div>

    //                 <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
    //                     <div className="flex mb-2">
    //                         {addClientEmail ? (
    //                             <>
    //                                 <div className="font-title flex-1 text-left">
    //                                     <div
    //                                         className="flex-1"
    //                                         style={{
    //                                             fontFamily: "poppins",
    //                                             fontSize: "14px",
    //                                             fontWeight: 700,
    //                                         }}
    //                                     >
    //                                         Email
    //                                     </div>
    //                                     <input
    //                                         autoComplete="nope"
    //                                         placeholder="Email (Optional)"
    //                                         className={"border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"}
    //                                         style={{
    //                                             fontFamily: "poppins",
    //                                             fontSize: "14px",
    //                                             fontWeight: 400,
    //                                             borderColor: formData.isClientEmail
    //                                                 ? "#008065"
    //                                                 : "#FF0000",
    //                                         }}
    //                                         name='clientEmail'
    //                                         value={formData.clientEmail}
    //                                         onChange={(e) => handleChange(e)}
    //                                     />

    //                                     {formData.errors?.clientEmailErrorMessage && (
    //                                         <span
    //                                             style={{
    //                                                 fontFamily: "poppins",
    //                                                 fontWeight: 700,
    //                                                 color: "#ff0000",
    //                                             }}
    //                                             className="text-red-400 text-xs"
    //                                         >
    //                                             {formData.errors.clientEmailErrorMessage}
    //                                         </span>
    //                                     )}
    //                                 </div>
    //                                 <button
    //                                     type="button"
    //                                     onClick={() =>
    //                                         handleClientAddEmail("addTrueClientEmail")
    //                                     }
    //                                 >
    //                                     <svg
    //                                         className="h-6 mr-3 text-grey"
    //                                         fill="none"
    //                                         viewBox="0 0 24 24"
    //                                         stroke="currentColor"
    //                                     >
    //                                         <path
    //                                             strokeLinecap="round"
    //                                             strokeLinejoin="round"
    //                                             strokeWidth="1"
    //                                             d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
    //                                         />
    //                                     </svg>
    //                                 </button>
    //                             </>
    //                         ) : (
    //                             <>
    //                                 <div
    //                                     className=" flex-1"
    //                                     style={{
    //                                         fontFamily: "poppins",
    //                                         fontSize: "14px",
    //                                         fontWeight: 700,
    //                                     }}
    //                                 >
    //                                     <button
    //                                         type="button"
    //                                         className="rounded-xl font-bold text-[#008065] flex flex-row  md:h-10 text-sm h-8   block w-48 "
    //                                         onClick={() =>
    //                                             handleClientAddEmail("addClientEmail")
    //                                         }
    //                                     >
    //                                         <EmailIcon
    //                                             style={IconStyle}
    //                                             className="h-5 w-5 "
    //                                         />
    //                                         Add Email
    //                                     </button>
    //                                 </div>
    //                             </>
    //                         )}
    //                     </div>

    //                     <div className="flex mb-2">
    //                         {addclientMobile ? (
    //                             <>
    //                                 <div className="font-title flex-1 text-left">
    //                                     <div
    //                                         className="flex-1"
    //                                         style={{
    //                                             fontFamily: "poppins",
    //                                             fontSize: "14px",
    //                                             fontWeight: 700,
    //                                         }}
    //                                     >
    //                                         Mobile
    //                                     </div>
    //                                     <input
    //                                         type="text"
    //                                         autoComplete="nope"
    //                                         placeholder="Mobile Number"
    //                                         className={`border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full ${clientErrors.clientMobile || !formData?.isclientMobile ? 'border-red-500' : 'border-[#008065]'
    //                                             }`}
    //                                         style={{
    //                                             fontFamily: "poppins",
    //                                             fontSize: "14px",
    //                                             fontWeight: 400,
    //                                         }}
    //                                         maxLength={10}
    //                                         name="clientMobile"
    //                                         value={formData.clientMobile}
    //                                         onChange={(e) => {
    //                                             const re = /^[0-9\b]+$/;
    //                                             if (re.test(e.target.value) || (e.target.value == "")) {
    //                                                 handleChange(e)
    //                                             }

    //                                         }}

    //                                     />

    //                                     {formData.errors?.clientMobileErrorMessage && (
    //                                         <p
    //                                             style={{
    //                                                 fontFamily: "poppins",
    //                                                 fontWeight: 700,
    //                                                 color: "#ff0000",
    //                                             }}
    //                                             className="text-red-400 text-xs"
    //                                         >
    //                                             {formData.errors.clientMobileErrorMessage}
    //                                         </p>
    //                                     )}
    //                                     {clientErrors.clientMobile && (
    //                                         <p
    //                                             style={{
    //                                                 fontFamily: "poppins",
    //                                                 fontWeight: 700,
    //                                                 color: "#ff0000",
    //                                             }}
    //                                             className="text-red-400 text-xs"
    //                                         >
    //                                             {clientErrors.clientMobile}
    //                                         </p>
    //                                     )}
    //                                 </div>
    //                                 <button
    //                                     type="button"
    //                                     onClick={() =>
    //                                         clientMobile("addTrueClientEmail")
    //                                     }
    //                                 >
    //                                     <svg
    //                                         className="h-6 mr-3 text-grey"
    //                                         fill="none"
    //                                         viewBox="0 0 24 24"
    //                                         stroke="currentColor"
    //                                     >
    //                                         <path
    //                                             strokeLinecap="round"
    //                                             strokeLinejoin="round"
    //                                             strokeWidth="1"
    //                                             d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
    //                                         />
    //                                     </svg>
    //                                 </button>
    //                             </>
    //                         ) : (
    //                             <>
    //                                 <div
    //                                     className=" flex-1"
    //                                     style={{
    //                                         fontFamily: "poppins",
    //                                         fontSize: "14px",
    //                                         fontWeight: 700,
    //                                     }}
    //                                 >
    //                                     <button
    //                                         type="button"
    //                                         className="rounded-xl font-bold text-[#008065] flex flex-row  md:h-10 text-sm h-8   block w-48 "
    //                                         onClick={() =>
    //                                             clientMobile("addtTrueClientno")
    //                                         }
    //                                     >
    //                                         <MobileIcon
    //                                             style={IconStyle}
    //                                             className="h-5 w-5 "
    //                                         />
    //                                         Add Mobile
    //                                     </button>
    //                                 </div>
    //                             </>
    //                         )}
    //                     </div>


    //                     <div className="flex mb-2">
    //                         {addClientPan ? (
    //                             <>
    //                                 <div className="font-title flex-1 text-left ">
    //                                     <div
    //                                         className="flex-1"
    //                                         style={{
    //                                             fontFamily: "poppins",
    //                                             fontSize: "14px",
    //                                             fontWeight: 700,
    //                                         }}
    //                                     >
    //                                         Pan No
    //                                     </div>
    //                                     <input
    //                                         autoComplete="nope"
    //                                         placeholder="PanNumber (Optional)"
    //                                         className={"border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"}
    //                                         style={{
    //                                             fontFamily: "poppins",
    //                                             fontSize: "14px",
    //                                             fontWeight: 400,
    //                                             borderColor: formData.isClientPan ? "#008065" : "#FF0000",
    //                                         }}
    //                                         name='clientPan'
    //                                         value={formData.clientPan}
    //                                         onChange={(e) => handleChange(e)}
    //                                     />
    //                                     {formData.errors?.clientPanErrorMessage && (
    //                                         <span
    //                                             style={{
    //                                                 fontFamily: "poppins",
    //                                                 fontWeight: 700,
    //                                                 color: "#ff0000",
    //                                             }}
    //                                             className="text-red-400 text-xs"
    //                                         >
    //                                             {formData.errors.clientPanErrorMessage}
    //                                         </span>
    //                                     )}

    //                                 </div>
    //                                 <button
    //                                     type="button"
    //                                     onClick={() =>
    //                                         handleClientAddPan("addTrueClientPanNo")
    //                                     }
    //                                 >
    //                                     <svg
    //                                         className="h-6 mr-3 text-grey"
    //                                         fill="none"
    //                                         viewBox="0 0 24 24"
    //                                         stroke="currentColor"
    //                                     >
    //                                         <path
    //                                             strokeLinecap="round"
    //                                             strokeLinejoin="round"
    //                                             strokeWidth="1"
    //                                             d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
    //                                         />
    //                                     </svg>
    //                                 </button>
    //                             </>
    //                         ) : (
    //                             <>
    //                                 <div
    //                                     className=" flex-1"
    //                                     style={{
    //                                         fontFamily: "poppins",
    //                                         fontSize: "14px",
    //                                         fontWeight: 700,
    //                                     }}
    //                                 >
    //                                     <button
    //                                         type="button"
    //                                         className="rounded-xl font-bold text-[#008065] flex flex-row  md:h-10 text-sm h-8   block w-48 "
    //                                         onClick={() =>
    //                                             handleClientAddPan("addClientPan")
    //                                         }
    //                                     >
    //                                         <PlusCircleIcon
    //                                             style={IconStyle}
    //                                             className="h-5 w-5"
    //                                         />
    //                                         Add PAN
    //                                     </button>
    //                                 </div>
    //                             </>
    //                         )}
    //                     </div>
    //                     <div className="flex mb-2">
    //                         {addClientGst ? (
    //                             <>
    //                                 <div className="font-title flex-1 text-left ">
    //                                     <div
    //                                         className="flex-1"
    //                                         style={{
    //                                             fontFamily: "poppins",
    //                                             fontSize: "14px",
    //                                             fontWeight: 700,
    //                                         }}
    //                                     >
    //                                         GST
    //                                     </div>
    //                                     <input
    //                                         autoComplete="nope"
    //                                         placeholder="GST (Optional)"
    //                                         className={"border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"}
    //                                         style={{
    //                                             fontFamily: "poppins",
    //                                             fontSize: "14px",
    //                                             fontWeight: 400,
    //                                             borderColor: formData.isClientGst ? "#008065" : "#FF0000",
    //                                         }}
    //                                         name='clientgst'
    //                                         value={formData.clientgst}
    //                                         onChange={(e) => handleChange(e)}
    //                                     />
    //                                     {formData.errors?.clientgstErrorMessage && (
    //                                         <span
    //                                             style={{
    //                                                 fontFamily: "poppins",
    //                                                 fontWeight: 700,
    //                                                 color: "#FF0000",
    //                                             }}
    //                                             className="text-red-400 text-xs"
    //                                         >
    //                                             {formData.errors.clientgstErrorMessage}
    //                                         </span>
    //                                     )}
    //                                 </div>
    //                                 <button
    //                                     type="button"
    //                                     onClick={() =>
    //                                         handleClientAddGst("addTrueClientGst")
    //                                     }
    //                                 >
    //                                     <svg
    //                                         className="h-6 mr-3 text-grey"
    //                                         fill="none"
    //                                         viewBox="0 0 24 24"
    //                                         stroke="currentColor"
    //                                     >
    //                                         <path
    //                                             strokeLinecap="round"
    //                                             strokeLinejoin="round"
    //                                             strokeWidth="1"
    //                                             d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
    //                                         />
    //                                     </svg>
    //                                 </button>
    //                             </>
    //                         ) : (
    //                             <>
    //                                 <div
    //                                     className=" flex-1"
    //                                     style={{
    //                                         fontFamily: "poppins",
    //                                         fontSize: "14px",
    //                                         fontWeight: 700,
    //                                     }}
    //                                 >
    //                                     <button
    //                                         type="button"
    //                                         className="rounded-xl font-bold text-[#008065] flex flex-row  md:h-10 text-sm h-8   block  w-full "
    //                                         onClick={() =>
    //                                             handleClientAddGst("addClientGst")
    //                                         }
    //                                     >
    //                                         <PlusCircleIcon
    //                                             style={IconStyle}
    //                                             className="h-5 w-5"
    //                                         />
    //                                         Add GST
    //                                     </button>
    //                                 </div>
    //                             </>
    //                         )}
    //                     </div>
    //                 </div>
    //             </div >
    //         </>
    //     )
    // }
    return (
        <div className={"flex flex-col sm:flex-row pt-3 pr-8"}>
            <div className="  flex-1 md:mx-2 mt-2 md:mt-[2px] ">
                <div className="flex flex-col md:flex-row justify-between items-left">
                    <div
                        className="flex flex-1 items-center whitespace-nowrap justify-start"
                        style={{
                            color: "#008065",
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                    >
                        Client Details (Billed To)
                    </div>

                    <div className="flex flex-1 items-center justify-center md:justify-end mr-2">
                        <ClientPlusIcon className="w-4 h-4" />
                        <Button onClick={handleConfirm}>
                            <span
                                style={{
                                    color: "#008065",
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    fontWeight: 700,
                                }}
                                className="text-[#008065] ml-1"
                            >
                                EXISTING
                            </span>
                        </Button>
                    </div>


                    {isModalOpen && (
                        <ClientModel closeModal={closeModal} handleSelect={handleSelect} />
                    )}
                </div>



                <div className="flex flex-row justify-between items-center mb-2">
                    <div
                        className="flex-1  required"
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                    >
                        <span>Name</span>
                    </div>
                    <div className="font-title flex-1 text-left">

                        <input
                            autoComplete="nope"
                            placeholder="Client Name"
                            className={"border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 400,
                                borderColor: clientErrors.clientName ? "red" : "#008065",
                            }}
                            name='clientName'
                            value={formData.clientName}
                            onChange={(e) => handleChange(e)}
                        />
                        {clientErrors.clientName && (
                            <p
                                style={{
                                    fontFamily: "poppins",
                                    fontWeight: 700,
                                    color: "#ff0000",
                                }}
                                className="text-red-400 text-xs"
                            >
                                {clientErrors.clientName}
                            </p>
                        )}

                    </div>
                </div>

                <div className="flex flex-row justify-between items-center mb-2">
                    <div
                        className=" flex-1 mb-2"
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                    >

                        Address
                    </div>
                    <div className="font-title flex-1 text-left">
                        <input
                            autoComplete="nope"
                            placeholder="Address (Optional)"
                            className={"border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 400,
                                borderColor: "#008065",
                            }}
                            name='clientAddress'
                            value={formData.clientAddress}
                            onChange={(e) => handleChange(e)}
                        />

                    </div>
                </div>

                <div className="flex flex-row justify-between items-center mb-2">
                    <div
                        className="flex-1"
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                    >
                        City
                    </div>
                    <div className="font-title flex-1 text-left ">

                        <input
                            autoComplete="nope"
                            placeholder="City (Optional)"
                            className={"border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 400,
                                borderColor: "#008065",
                            }}
                            name='clientCity'
                            value={formData.clientCity}
                            onChange={(e) => handleChange(e)}
                        />


                    </div>
                </div>

                <div className="flex flex-row justify-between items-center mb-2">
                    <div
                        className="flex-1"
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                    >
                        State
                    </div>
                    <div className="font-title flex-1 text-left ">

                        <input
                            autoComplete="nope"
                            placeholder="State (Optional)"
                            className={"border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 400,
                                borderColor: "#008065",
                            }}
                            name='clientState'
                            value={formData.clientState}
                            onChange={(e) => handleChange(e)}
                        />


                    </div>
                </div>

                <div className="flex flex-row justify-between items-center mb-2">
                    <div
                        className="flex-1"
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                    >
                        Postal Code
                    </div>
                    <div className="font-title flex-1 text-left ">
                        <input
                            type=""
                            autoComplete="nope"
                            placeholder="Postalcode (Optional)"
                            className={"border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 400,
                                borderColor: formData.isclientPostalCode
                                    ? "#008065"
                                    : "#FF0000",
                            }}
                            maxLength={6}
                            name='clientPostalCode'
                            value={formData.clientPostalCode}
                            onChange={(e) => {
                                const re = /^[0-9\b]+$/;

                                if (re.test(e.target.value) || (e.target.value == "")) {
                                    handleChange(e)
                                }

                            }}
                        />
                        {formData.errors?.clientPostalCodeErrorMessage && (
                            <span
                                style={{
                                    fontFamily: "poppins",
                                    fontWeight: 700,
                                    color: "#ff0000",
                                }}
                                className="text-red-400 text-xs"
                            >
                                {formData.errors.clientPostalCodeErrorMessage}
                            </span>
                        )}
                    </div>
                </div>


            </div >
            <div className="  flex-1 md:mx-2">
                <div>
                    <div className="sm:flex-1 mt-2 p-2">
                        <div className="flex flex-row justify-between items-center mb-2 mr-6">
                            <div
                                style={{
                                    color: "#008065",
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 700,
                                }}
                                className=" flex-1 pr-8 invoiceUniqNo"
                            >
                                INVOICE #
                            </div>
                            <div className="font-title flex-1 text-right ">
                                <input
                                    name="invoiceNo"
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        borderColor: "#008065",
                                    }}
                                    value={billModules == 'PRODUCT' ? formData.invoiceNo : serviceBillNo}
                                    onChange={(e) => handleChange(e)}
                                    autoComplete="nope"
                                    placeholder="Invoice No"
                                    className={
                                        " text-center font-medium h-10 text-sm flex-1 font-title text-md px-2 block border-solid border-2 rounded-[7px] focus:outline-none w-[130px] sm:w-[157px]"
                                    }
                                />
                            </div>
                        </div>

                        <div className="flex flex-row justify-between items-center mb-2 mr-6">
                            <div
                                // id="creationDate"
                                style={{
                                    color: "#008065",
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 700,
                                }}
                                className="flex-1 pr-8 "
                            >
                                Invoice Date
                            </div>
                            <div className=" font-title flex-1 text-left">
                                <DatePicker
                                    // style={{ fill: "red !important" }}
                                    selected={invoiceDate}
                                    value={formData?.invoicedate}
                                    onChange={(date) => {
                                        setInvoiceDate(date);
                                        setFormData({
                                            ...formData,
                                            invoicedate: moment(date).format("DD/MM/YYYY"),
                                        });
                                    }}
                                    dateFormat={"dd/MM/yyyy"}
                                    wrapperClassName="invoice-datepicker"
                                    scrollableYearDropdown
                                    customInput={<CustomInputWithIcon />}
                                    showPopperArrow={false}

                                />
                            </div>

                        </div>
                        {billModules == 'PRODUCT' && (
                            <>

                                <div className="flex flex-row justify-between items-center mb-2 ">
                                    <div
                                        style={{
                                            color: "#008065",
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                        }}
                                        className=" flex-1 pr-8"
                                        id="dueDate"
                                    >
                                        Due Date
                                    </div>
                                    <div className=" flex-1  -ml-5 flex text-left relative">
                                        <div>
                                            <DatePicker
                                                // style={{ fill: "red !important" }}
                                                selected={dueDate}
                                                onChange={(date) => {
                                                    setDueDate(date);
                                                    setFormData({
                                                        ...formData,
                                                        duedate: moment(date).format("DD/MM/YYYY"),
                                                    });
                                                }}
                                                dateFormat={"dd/MM/yyyy"}
                                                wrapperClassName="due-datepicker"
                                                scrollableYearDropdown
                                                customInput={<CustomDueDate />}
                                                showPopperArrow={false}
                                            />
                                        </div>
                                    </div>

                                </div>
                                {/* {formData?.duedate ? (
                                    <div className="flex flex-row justify-between items-center mb-2 ">
                                        <div
                                            style={{
                                                color: "#008065",
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 700,
                                            }}
                                            className=" flex-1 pr-8"
                                            id="dueDate"
                                        >
                                            Due Date
                                        </div>
                                        <div className=" flex-1 flex text-left relative">
                                            <div>
                                                <DatePicker
                                                    // style={{ fill: "red !important" }}
                                                    selected={moment(
                                                        formData.duedate,
                                                        "DD/MM/YYYY",
                                                    ).toDate()}
                                                    onChange={(date) => {
                                                        // setDueDate(date);
                                                        setFormData({
                                                            ...formData,
                                                            duedate: moment(date).format("DD/MM/YYYY"),
                                                        });
                                                    }}
                                                    dateFormat={"dd/MM/yyyy"}
                                                    scrollableYearDropdown
                                                    customInput={<CustomDueDate />}
                                                    showPopperArrow={false}
                                                />
                                            </div>
                                            <div>
                                                <button
                                                    type="button"
                                                    onClick={handleRemoveDate}
                                                    id="combo_2"
                                                    aria-label="Aria Name"
                                                >
                                                    <svg
                                                        className="h-6  text-grey"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="1"
                                                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                ) : (null)} */}
                            </>
                        )}

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                            <div className="flex mb-2 ">
                                {addClientEmail ? (
                                    <>
                                        <div className="font-title flex-1 text-left">
                                            {/* <div
                                                className="flex-1"
                                                style={{
                                                    fontFamily: "poppins",
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                }}
                                            >
                                                Email
                                            </div> */}
                                            <input
                                                autoComplete="nope"
                                                placeholder="Email (Optional)"
                                                className={"border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"}
                                                style={{
                                                    fontFamily: "poppins",
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    borderColor: formData.isClientEmail
                                                        ? "#008065"
                                                        : "#FF0000",
                                                }}
                                                name='clientEmail'
                                                value={formData.clientEmail}
                                                onChange={(e) => handleChange(e)}
                                            />

                                            {formData.errors?.clientEmailErrorMessage && (
                                                <span
                                                    style={{
                                                        fontFamily: "poppins",
                                                        fontWeight: 700,
                                                        color: "#ff0000",
                                                    }}
                                                    className="text-red-400 text-xs"
                                                >
                                                    {formData?.errors?.clientEmailErrorMessage}
                                                </span>
                                            )}
                                        </div>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                handleClientAddEmail("addTrueClientEmail")
                                            }
                                        >
                                            <svg
                                                // className="h-6 mr-3 text-grey  "
                                                className={`h-6 mr-3  text-grey ${formData?.errors?.clientEmailErrorMessage
                                                    ? "mb-6"
                                                    : ""
                                                    }`}
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="1"
                                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                />
                                            </svg>
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className=" flex-1"
                                            style={{
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 700,
                                            }}
                                        >
                                            <button
                                                type="button"
                                                className="rounded-xl font-bold text-[#008065] flex flex-row  md:h-10 text-sm h-8   block w-48 "
                                                onClick={() =>
                                                    handleClientAddEmail("addClientEmail")
                                                }
                                            >
                                                <EmailIcon
                                                    style={IconStyle}
                                                    className="h-5 w-5 "
                                                />
                                                Add Email
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>

                            <div className="flex mb-2">
                                {addclientMobile ? (
                                    <>
                                        <div className="font-title flex-1 text-left">
                                            {/* <div
                                                className="flex-1"
                                                style={{
                                                    fontFamily: "poppins",
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                }}
                                            >
                                                Mobile
                                            </div> */}
                                            <input
                                                type="text"
                                                autoComplete="nope"
                                                placeholder="Mobile Number"
                                                className={`border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full ${!formData?.isclientMobile ? 'border-red-500' : 'border-[#008065]'
                                                    }`}
                                                style={{
                                                    fontFamily: "poppins",
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                }}
                                                maxLength={10}
                                                name="clientMobile"
                                                value={formData.clientMobile}
                                                onChange={(e) => {
                                                    const re = /^[0-9\b]+$/;
                                                    if (re.test(e.target.value) || (e.target.value == "")) {
                                                        handleChange(e)
                                                    }

                                                }}

                                            />

                                            {formData.errors?.clientMobileErrorMessage && (
                                                <p
                                                    style={{
                                                        fontFamily: "poppins",
                                                        fontWeight: 700,
                                                        color: "#ff0000",
                                                    }}
                                                    className="text-red-400 text-xs"
                                                >
                                                    {formData.errors.clientMobileErrorMessage}
                                                </p>
                                            )}

                                        </div>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                clientMobile("addTrueClientEmail")
                                            }
                                        >
                                            <svg
                                                className={`h-6 mr-3  text-grey ${formData.errors?.clientMobileErrorMessage
                                                    ? "mb-5"
                                                    : ""
                                                    }`}
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="1"
                                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                />
                                            </svg>
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className=" flex-1"
                                            style={{
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 700,
                                            }}
                                        >
                                            <button
                                                type="button"
                                                className="rounded-xl font-bold text-[#008065] flex flex-row  md:h-10 text-sm h-8   block w-48 "
                                                onClick={() =>
                                                    clientMobile("addtTrueClientno")
                                                }
                                            >
                                                <MobileIcon
                                                    style={IconStyle}
                                                    className="h-5 w-5 "
                                                />
                                                Add Mobile
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>


                            <div className="flex mb-2">
                                {addClientPan ? (
                                    <>
                                        <div className="font-title flex-1 text-left ">
                                            {/* <div
                                                className="flex-1"
                                                style={{
                                                    fontFamily: "poppins",
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                }}
                                            >
                                                Pan No
                                            </div> */}
                                            <input
                                                autoComplete="nope"
                                                placeholder="PanNumber (Optional)"
                                                className={"border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"}
                                                style={{
                                                    fontFamily: "poppins",
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    borderColor: formData.isClientPan ? "#008065" : "#FF0000",
                                                }}
                                                name='clientPan'
                                                value={formData.clientPan}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            {formData.errors?.clientPanErrorMessage && (
                                                <span
                                                    style={{
                                                        fontFamily: "poppins",
                                                        fontWeight: 700,
                                                        color: "#ff0000",
                                                    }}
                                                    className="text-red-400 text-xs"
                                                >
                                                    {formData.errors.clientPanErrorMessage}
                                                </span>
                                            )}

                                        </div>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                handleClientAddPan("addTrueClientPanNo")
                                            }
                                        >
                                            <svg
                                                className={`h-6 mr-3  text-grey ${formData.errors?.clientPanErrorMessage
                                                    ? "mb-6"
                                                    : ""
                                                    }`}
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="1"
                                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                />
                                            </svg>
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className=" flex-1"
                                            style={{
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 700,
                                            }}
                                        >
                                            <button
                                                type="button"
                                                className="rounded-xl font-bold text-[#008065] flex flex-row  md:h-10 text-sm h-8   block w-48 "
                                                onClick={() =>
                                                    handleClientAddPan("addClientPan")
                                                }
                                            >
                                                <PlusCircleIcon
                                                    style={IconStyle}
                                                    className="h-5 w-5"
                                                />
                                                Add PAN
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="flex mb-2">
                                {addClientGst ? (
                                    <>
                                        <div className="font-title flex-1 text-left ">
                                            {/* <div
                                                className="flex-1"
                                                style={{
                                                    fontFamily: "poppins",
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                }}
                                            >
                                                GST
                                            </div> */}
                                            <input
                                                autoComplete="nope"
                                                placeholder="GST (Optional)"
                                                className={"border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"}
                                                style={{
                                                    fontFamily: "poppins",
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    borderColor: formData.isClientGst ? "#008065" : "#FF0000",
                                                }}
                                                name='clientgst'
                                                value={formData.clientgst}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            {formData.errors?.clientgstErrorMessage && (
                                                <span
                                                    style={{
                                                        fontFamily: "poppins",
                                                        fontWeight: 700,
                                                        color: "#FF0000",
                                                    }}
                                                    className="text-red-400 text-xs"
                                                >
                                                    {formData.errors.clientgstErrorMessage}
                                                </span>
                                            )}
                                        </div>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                handleClientAddGst("addTrueClientGst")
                                            }
                                        >
                                            <svg
                                                className={`h-6 mr-3  text-grey ${formData.errors?.clientgstErrorMessage
                                                    ? "mb-6"
                                                    : ""
                                                    }`}
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="1"
                                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                />
                                            </svg>
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className=" flex-1"
                                            style={{
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 700,
                                            }}
                                        >
                                            <button
                                                type="button"
                                                className="rounded-xl font-bold text-[#008065] flex flex-row  md:h-10 text-sm h-8   block  w-full "
                                                onClick={() =>
                                                    handleClientAddGst("addClientGst")
                                                }
                                            >
                                                <PlusCircleIcon
                                                    style={IconStyle}
                                                    className="h-5 w-5"
                                                />
                                                Add GST
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div >
    )

}

export default ClientDetails


