/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog, Transition } from "@headlessui/react";
import moment from "moment";
import { toWords } from "number-to-words";
import React, {
  Fragment,
  useEffect,
  useRef,
  useState
} from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useData } from "../../layouts/shared/DataProvider";
import { fontFamilyGet } from "../../redux/actions/FontFamilyAction";
import {
  InternalGetCount
} from "../../redux/actions/InternalInvoiceAction";
import { currencyGet } from "../../redux/actions/currencyAction";
import { salesReturnFindOne, salesReturnPost, SalesReturnUpdate } from "../../redux/actions/salesReturnAction";
import { registerAll } from "../../redux/actions/userAction";
import api from "../../redux/axiosConfig";
import { encryptData } from "../../redux/cryptoConfig";
import ConfirmationDownload from "../commen/ConfirmationDownload";
import NumberFormat from "../commen/NumberFormat";
import SendEmailModel from "../models/SendEmailModel";
import "./../../layouts/App/App.css";
import ClientDetailsReturn from "./InvoiceDetails/ClientDetailsReturn";
import SalesGstSection from "./InvoiceDetails/SalesGstSection";
import SalesReturnProducts from "./InvoiceDetails/SalesReturnProducts";
import Preview from "./Preview";
import PreviewNewProductwise from "./PreviewNewProductwise";
import Print from "./Print";
import PrintProductwise from "./PrintProductwise";
import PrintService from "./PrintService";
import SalesReturnNewProducts from "./SalesReturnNewProducts";
import { defaultInputSmStyle } from "../commen/defaultStyles";
import { toast } from "react-toastify";

function InvoicePage() {
  const dispatch = useDispatch<any>();
  const params = useParams();
  const { id } = params;
  const usersignin = useSelector((state: any) => state?.userSignin);
  const token = usersignin?.userInfo?.token;
  const template = useSelector((state: any) => state.userColorTemplate);
  const { userdata } = template;
  const { selectedFont, setSelectedFont }: any = useData();
  const [selectedPayment, setSelectedPayment] = useState("");
  const [balanceAmount, setbalanceAmount] = useState(0 as any);
  const [CashAmount, setCashAmount] = useState(0 as any);
  const [receivedAmount, setreceivedAmount] = useState(0 as any);

  // ****************************Date Picker and Date Section******************************************
  const [addValue, setAddValue] = useState(false);

  const handleAddvalue = () => {
    if (!addValue) {
      setFormData({
        ...formData,
        duedate: moment(new Date()).format("DD/MM/YYYY"),
      });
    }
    setAddValue(!addValue);
  };

  const handleRemoveDate = () => {
    setFormData({
      ...formData,
      duedate: "", // Set duedate to an empty string
    });
  };

  const [invoiceUpdateId, setInvoiceUpdateId] = useState(null as any);
  const [invoiceDate, setInvoiceDate] = useState(new Date() as any);
  const [dueDate, setDueDate] = useState(new Date() as any);
  const [billModules, setbillModule] = useState(null as any);
  const [invoiceDetails, setInvoiceDetails] = useState(null as any);
  const [salesReturnDate, setSalesReturnDate] = useState(new Date() as any);
  const formatDate = moment(salesReturnDate).format("DD/MM/YYYY");
  const location = useLocation();
  const invoiceModule = location.state;
  const [newExistProduct, setNewexistProduct] = useState([{ exist: "", originalValue: '' }] as any);


  // ***********************************************************************

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [formData, setFormData] = useState({
    // errorMessage: "",
    // errors: {},
    invoicehead: "SALES RETURN",
    invoiceNo: "",
    SalesreturnNo: "",
    salesReturnDate: formatDate,
    invoicedate: "",
    duedate: addValue ? moment(dueDate).format("DD/MM/YYYY") : "",
    imageData: "",
    companyName: "",
    companyAddress: "",
    companyCity: "",
    companyState: "",
    companyPostalCode: "",
    companyMobile: "",
    companyEmail: "",
    companyPan: "",
    companygst: "",
    clientName: "",
    clientMobile: "",
    clientEmail: "",
    clientPan: "",
    subtotal: 0,
    toatalAmount: 0,
    nontaxableTotal: 0,
    product: [],
    tax: [],
    extrafees: [],
    user_id: token?._id,
    To: "",
    Subject: "",
    totalInWords: "",
    invoiceTemplateType: "",
    colorTemplateType: "",
    billModule: billModules,
    paymentType: selectedPayment,
    cashAmount: "",
    balanceAmount: balanceAmount,
    receivedAmount: receivedAmount,
    paymentStatus: "PAID",
    gstType: "",
    clientId: "",
    paymentOutReceiptNo: "",
  } as any);

  useEffect(() => {
    dispatch(fontFamilyGet(token?._id) as any).then((res: any) => {
      if (res?.type === "FONTFAMILY_GET_SUCCESS") {
        let data = res?.payload?.find(
          (value: any) => value?.defaultInfo === true,
        );
        if (data) {
          setSelectedFont(data?.fontfamily);
        } else {
          setSelectedFont("poppins");
        }
      }
    });
  }, []);

  useEffect(() => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      balanceAmount: balanceAmount ? balanceAmount : '',
      receivedAmount: receivedAmount ? Number(receivedAmount) : 0,
      cashAmount: CashAmount ? CashAmount : '',
      // creditAmount:creditAmount ? creditAmount :"",
      // chequeAmount:chequeAmount ? chequeAmount : "",
      paymentType: selectedPayment ? selectedPayment : "",
    }));
  }, [balanceAmount, receivedAmount, CashAmount]);

  const [currencySymbol, setcurrencySymbol] = useState("₹");

  useEffect(() => {

    dispatch(currencyGet(token?._id) as any).then((res: any) => {
      if (res) {
        let data = res.payload.find((value: any) => value.defaultInfo === true);
        if (data?.currencySymbol) {
          setcurrencySymbol(data.currencySymbol);
        } else {
          setcurrencySymbol("₹");
        }
      }
    });
  }, []);


  useEffect(() => {

    dispatch(currencyGet(token?._id) as any).then((res: any) => {
      if (res) {
        let data = res.payload.find((value: any) => value.defaultInfo === true);
        if (data?.currencySymbol) {
          setcurrencySymbol(data.currencySymbol);
        } else {
          setcurrencySymbol("₹");
        }
      }
    });
  }, []);


  // ****************************************Image section***************************************************



  const [clientErrors, setClientErrors] = useState<{
    clientName?: string;
    invoiceNo?: string;
    paymentType?: string;
  }>({});

  const [sendEmailErrors, setSendEmailErrors] = useState<{ To?: string }>({});

  const [selectedClientData, setSelectedClientData] = useState({
    clientName: '',
    clientMobile: '',
    clientEmail: '',
    clientPan: '',
    clientId: '',
    invoiceNo: '',
    invoiceDate: '',
    paymentType: '',
    _id: ""
  });

  const validateProdForm = () => {
    const errors: { clientName?: string; invoiceNo?: string; paymentType?: string } = {};

    if (formData.clientName.trim() === "") {
      errors.clientName = "Client Name is Required";
    }
    if (formData.invoiceNo.trim() === "") {
      errors.invoiceNo = "Invoice No is Required";
    }
    if (selectedClientData.paymentType.trim() === "") {
      errors.paymentType = "Payment Type is Required";
    }

    setClientErrors(errors);

    // Return false if there are any errors
    return Object.keys(errors).length === 0;
  };


  interface FieldErrors {
    cashAmount?: string;
  }

  const [errors, setErrors] = useState({} as any);
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    let newValue = value.trimStart();

    setErrors({
      ...errors,
      [name]: "",
    });
    const fieldName = name as keyof FieldErrors;

    let error = "";
    if (fieldName === "cashAmount" && value) {
      if (formData?.toatalAmount < value || value === "0") {
        error = "Invalid Amount";
      }
    }
    setFieldErrors((prevErrors: any) => ({
      ...prevErrors,
      [fieldName]: error,
    }));


    setSelectedClientData({
      ...selectedClientData,
      [name]: newValue,
    });

    setClientErrors({ ...clientErrors, [name]: "" });

    if (name === "paymentType") {
      setSelectedPayment(value);
      setbalanceAmount(0);
    }

    if (value === "CASH") {
      setCashAmount(formData.toatalAmount);
      setreceivedAmount(formData.toatalAmount)
    }

    if (name === "cashAmount") {
      let cashvalue = e.target.value;
      let balance = formData.toatalAmount - Number(cashvalue);
      setbalanceAmount(balance);
      setCashAmount(cashvalue);
      setreceivedAmount(cashvalue);
    }

    let errorMessage = "";
    let isSendEmail = true;

    if (name === "To" && value) {
      isSendEmail = emailRegex.test(value);
      errorMessage = isSendEmail ? "" : "Invalid Email Address";
    }

    setFormData({
      ...formData,
      [name]: newValue,
      errors: {
        ...formData.errors,
        [`${name}ErrorMessage`]: errorMessage,
      },
      isSendEmail: name === "To" ? isSendEmail : formData.isSendEmail,
    });
  };




  // ***********************************Start***********************************
  const [TaxAllList, setTaxAllList] = useState([] as any);

  const formatedDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };


  const fetchData = () => {
    dispatch(InternalGetCount() as any).then((data: any) => {
      if (data) {
        let companyResponse: any = JSON.parse(data?.payload?.company);
        let conpanylist = companyResponse?.find((x: any) => x);
        let tittleResponse: any = JSON.parse(data?.payload?.tittles);
        let tittleList = tittleResponse?.find((x: any) => x);
        // let dateResponses: any = JSON.parse(data?.payload?.SalesReturnCount);

        // setTaxAllList(JSON.parse(data?.payload?.appsettings));
        let newCountValue = data?.payload?.SalesReturnCount;
        let match = newCountValue?.match(/\d{4}$/);
        let result = "";
        if (match) {
          let value = match[0];
          result = (parseInt(value) + 1).toString().padStart(4, "0");
        }
        let invoice =
          `${"SR-"}` +
          String(result,
          );

        setFormData({
          ...formData,
          SalesreturnNo: invoice,
          paymentOutReceiptNo: data?.payload?.paymentOutReceiptCount,
          imageData: conpanylist?.companyimg ? conpanylist?.companyimg : "",
          // salesReturnDate: tittleList?.invoiceDate
          //   ? tittleList?.invoiceDate
          //   : formatDate,
          companyName: conpanylist?.company_name ? conpanylist?.company_name : "",
          companyAddress: conpanylist?.company_address ? conpanylist?.company_address : "",
          companyCity: conpanylist?.city ? conpanylist?.city : "",
          companyState: conpanylist?.state ? conpanylist?.state : "",
          companyPostalCode: conpanylist?.postal_code ? conpanylist?.postal_code : "",
          companyPan: conpanylist?.pan_no ? conpanylist?.pan_no : "",
          companygst: conpanylist?.gst ? conpanylist?.gst : "",
          companyMobile: conpanylist?.company_mobile ? conpanylist?.company_mobile : "",
          companyEmail: conpanylist?.company_email ? conpanylist?.company_email : ""
        });
      }
    });
  };



  useEffect(() => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      salesReturnDate: formData?.salesReturnDate ? formData?.salesReturnDate : formatDate,
      duedate: addValue ? moment(dueDate).format("DD/MM/YYYY") : "",
    }));
  }, [invoiceDate, dueDate, addValue, salesReturnDate]);


  const [confirmDownload, setConfirmDownload] = useState(false);
  const [prodcutRows, setProductRows] = useState<any>([
    {
      prodcut: "",
      hsn: "",
      rate: "",
      qty: "",
      igst: "",
      cgst: "",
      sgst: "",
      amount: 0,
      igstAmount: "",
      cgstAmount: "",
      sgstAmount: "",
    },
  ] as any);

  const [serviceRows, setServiceRows] = useState<any>([
    {
      service_name: "",
      rate: "",
      qty: "",
      amount: 0,
    },
  ] as any);

  const addRow = () => {
    setProductRows([
      ...prodcutRows,
      { prodcut: "", hsn: "", rate: "", qty: "", amount: 0, productName: "" },
    ]);

    setServiceRows([
      ...serviceRows,
      { service_name: "", rate: "", qty: "", amount: 0 },
    ]);
  };

  const getProductCal = (values: any) => {
    let products = [...values.product];
    let finalProduct = products?.map((item: any) => {
      item.prodcgst = TaxAllList[0]?.cgstPercentage || 0;
      item.prodsgst = TaxAllList[0]?.sgstPercentage || 0;
      item.prodigst = TaxAllList[0]?.igstPercentage || 0;
      item.prodvat = TaxAllList[0]?.vatPercentage || 0;
      item.prodothers = TaxAllList[0]?.othersPercentage || 0;
      item.totalgst =
        parseFloat(TaxAllList[0]?.cgstPercentage || 0) +
        parseFloat(TaxAllList[0]?.sgstPercentage || 0) +
        parseFloat(TaxAllList[0]?.igstPercentage || 0) +
        parseFloat(TaxAllList[0]?.vatPercentage || 0) +
        parseFloat(TaxAllList[0]?.othersPercentage || 0);
      item.grandTotal =
        ((parseFloat(item.totalgst) / 100) * parseFloat(item.rate) +
          parseFloat(item.rate)) *
        parseFloat(item.qty);
      item.total =
        (parseFloat(item.totalgst) / 100) * parseFloat(item.rate) +
        parseFloat(item.rate);
      item.creation_date = new Date();
      return item;
    });
    return finalProduct;
  };

  const fetchDownload = (data: any) => {
    formData?.product.forEach((item: any) => {
      item.amount = Number(item.amount).toFixed(2);
    });
    const totalAmount = formData.toatalAmount;
    const roundedtotalAmount = Math.round(totalAmount);
    const totalAmountInWords = Number.isFinite(roundedtotalAmount)
      ? toWords(roundedtotalAmount)
      : "Invalid amount";
    const totalAmountInWordsPrint = totalAmountInWords + " Only";
    formData.totalInWords = totalAmountInWordsPrint;
    const queryParams = new URLSearchParams();
    queryParams.append("param1", Colorpicker[0]);
    queryParams.append("param2", Colorpicker[1]);
    queryParams.append("param3", Colorpicker[2]);
    queryParams.append("param4", Colorpicker[3]);
    if (userdata?.template) queryParams.append("param5", userdata?.template);
    queryParams.append("param6", selectedFont);
    queryParams.append("param7", currencySymbol);
    if (userdata?.invoiceType)
      queryParams.append("param8", userdata?.invoiceType);
    formData.color_1 = Colorpicker[0];
    formData.color_2 = Colorpicker[1];
    formData.color_3 = Colorpicker[2];
    formData.color_4 = Colorpicker[3];
    formData.alt = userdata?.template;
    const url = `/api/salesReturn_pdf/download?${queryParams.toString()}`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: encryptData(JSON.stringify(formData)),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link: any = document.createElement("a");
        link.href = url;
        const formattedDate = moment().format("YYYY-MM-DD");
        link.setAttribute(
          "download",
          `invoice_${formData.clientName}_${formattedDate}.pdf`,
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setConfirmDownload(false);
      })
      .catch((error) => console.error("Error downloading PDF:", error));

  };

  const downloadInvoice = () => {
    fetchDownload(formData);
  };

  const [invoiceUpdateNewId, setInvoiceUpdateNewId] = useState(null as any)
  const saveAndDownloadInvoice = () => {
    const isValid = validateProdForm();

    if (!isValid) {
      return;
    }
    if (!id && !invoiceUpdateId) {
      formData.salesReturnDate = formatDate;
      formData.product = getProductCal(formData);
      formData.billTaxType = userdata?.taxType
      if (billModules != "SERVICE") formData.invoiceTemplateType = userdata?.invoiceType
      formData.colorTemplateType = userdata?.template
      if (invoiceUpdateNewId) {

      }
      else {
        dispatch(salesReturnPost(formData) as any)
          .then((response: any) => {
            setInvoiceUpdateNewId(response?.payload?.invoice_id)
            formData?.product.forEach((item: any) => {
              item.amount = Number(item.amount).toFixed(2);
            });

            if (response?.payload?.invoice_id) {
              const queryParams = new URLSearchParams();
              queryParams.append("param1", Colorpicker[0]);
              queryParams.append("param2", Colorpicker[1]);
              queryParams.append("param3", Colorpicker[2]);
              queryParams.append("param4", Colorpicker[3]);
              if (userdata?.template) {
                queryParams.append("param5", userdata?.template);
              }
              queryParams.append("param6", selectedFont);
              queryParams.append("param7", currencySymbol);
              if (userdata?.invoiceType) {
                queryParams.append("param8", userdata?.invoiceType);
              }

              const totalAmount = formData.toatalAmount;
              const roundedtotalAmount = Math.round(totalAmount);
              const totalAmountInWords = Number.isFinite(roundedtotalAmount)
                ? toWords(roundedtotalAmount)
                : "Invalid amount";
              const totalAmountInWordsPrint = totalAmountInWords + " Only.";
              formData.totalInWords = totalAmountInWordsPrint;
              const url = `/api/salesReturn_pdf/${response?.payload?.invoice_id
                }?${queryParams.toString()}`;
              fetch(url, {
                method: "GET",
              })
                .then((response) => {
                  if (!response.ok) {
                    throw new Error("Network response was not ok");
                  }
                  return response.blob();
                })
                .then((blob) => {
                  const url = window.URL.createObjectURL(new Blob([blob]));
                  const link: any = document.createElement("a");
                  link.href = url;
                  const formattedDate = moment().format("YYYY-MM-DD");
                  link.setAttribute(
                    "download",
                    `invoice_${formData.clientName}_${formattedDate}.pdf`,
                  );
                  document.body.appendChild(link);
                  link.click();
                  link.parentNode.removeChild(link);
                  setInvoiceUpdateId(response?.payload?.invoice_id);
                  setConfirmDownload(false);
                })
                .catch((error) => console.error("Error downloading PDF:", error));
            }
          });
      }
    }
    else if (id || invoiceUpdateId) {
      let invoiceId = id ? id : invoiceUpdateId;
      formData.salesReturnDate = formatDate;
      formData.product = getProductCal(formData);
      formData?.product.forEach((item: any) => {
        item.amount = Number(item.amount).toFixed(2);
      });
      dispatch(SalesReturnUpdate(invoiceId, formData) as any).then(
        (response: any) => {
          const queryParams = new URLSearchParams();
          queryParams.append("param1", Colorpicker[0]);
          queryParams.append("param2", Colorpicker[1]);
          queryParams.append("param3", Colorpicker[2]);
          queryParams.append("param4", Colorpicker[3]);
          if (formData.colorTemplateType) {
            queryParams.append("param5", formData.colorTemplateType);
          }
          queryParams.append("param6", selectedFont);
          queryParams.append("param7", currencySymbol);
          if (formData.invoiceTemplateType) {
            queryParams.append("param8", formData.invoiceTemplateType);
          }
          const totalAmount = formData.toatalAmount;
          const roundedtotalAmount = Math.round(totalAmount);
          const totalAmountInWords = Number.isFinite(roundedtotalAmount)
            ? toWords(roundedtotalAmount)
            : "Invalid amount";
          const totalAmountInWordsPrint = totalAmountInWords + " Only";
          formData.totalInWords = totalAmountInWordsPrint;
          formData.receivedAmount = receivedAmount;
          const url = `/api/salesReturn_pdf/${invoiceId}?${queryParams.toString()}`;
          fetch(url, {
            method: "GET",
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.blob();
            })
            .then((blob) => {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link: any = document.createElement("a");
              link.href = url;
              const formattedDate = moment().format("YYYY-MM-DD");
              link.setAttribute(
                "download",
                `invoice_${formData.clientName}_${formattedDate}.pdf`,
              );
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              setConfirmDownload(false);
            })
            .catch((error) => console.error("Error downloading PDF:", error));
        },
      );

    }
  };



  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

    const isFormValid = validateProdForm();

    if (isFormValid && !fieldErrors.cashAmount) {
      setConfirmDownload(true);
    } else {
      setConfirmDownload(false);
    }
  };



  const [clientList, setClientList] = useState(
    userdata?.color ? userdata?.color : "#000000",
  );

  const [Colorpicker, setColorPicker] = useState([] as any);


  // *********************************************EMail Sctions Start**************************************

  const [openmail, setOpenMail] = useState(false as any);
  const handleSectionOpen = (e: any) => {
    if (formData.clientName) {
      setOpenMail(true);
    } else {
      validateProdForm();
      setConfirmDownload(false);
    }
  };

  const [errorEmail, setErrorEmail] = useState(null as any);
  const handleSendEmail = () => {
    if (formData.To?.trim() !== "" && emailRegex.test(formData.To)) {
      formData.invoicedate = formatDate;
      const emailViewPdf = {
        fromEmail: formData.From,
        toEmail: formData.To,
        subject: formData.Subject,
        message: formData.Message,
        clientName: formData.clientName,
      };
      if (!id) {
        if (invoiceUpdateNewId) {
          dispatch(SalesReturnUpdate(invoiceUpdateNewId, formData) as any).then(
            (response: any) => {
              const queryParams = new URLSearchParams();
              queryParams.append("param1", Colorpicker[0]);
              queryParams.append("param2", Colorpicker[1]);
              queryParams.append("param3", Colorpicker[2]);
              queryParams.append("param4", Colorpicker[3]);
              queryParams.append("param5", selectedFont);
              queryParams.append("param6", currencySymbol);
              if (userdata?.template) {
                queryParams.append("param7", userdata?.template);
              }
              if (userdata?.invoiceType) {
                queryParams.append("param8", userdata?.invoiceType);
              }
              fetch(
                `/api/send_email_Sales_Return_pdf/${invoiceUpdateNewId
                }?${queryParams.toString()}`,
              )
                .then((response) => {
                  if (!response.ok) {
                    throw new Error("Network response was not ok");
                  }
                  return response.blob();
                })
                .then((blob) => {
                  api.post(`/api/salesreturn/upload`, emailViewPdf)
                    .then((pdfResp: any) => {
                      console.log("Blob uploaded successfully:", pdfResp);
                      if (pdfResp.data.message) {
                        toast.success("Email Send Successfully", {
                          position: toast.POSITION.BOTTOM_CENTER,
                          autoClose: 800,
                        });
                      }
                    })
                    .catch((error: any) => {
                      console.error("Error uploading blob:", error);
                    });
                })
                .catch((error) => console.error("Error downloading PDF:", error));
            })
        }
        else {
          dispatch(salesReturnPost(formData) as any).then((response: any) => {
            setInvoiceUpdateNewId(response?.payload?.invoice_id)
            formData?.product.forEach((item: any) => {
              item.amount = Number(item.amount).toFixed(2);
            });
            const queryParams = new URLSearchParams();
            queryParams.append("param1", Colorpicker[0]);
            queryParams.append("param2", Colorpicker[1]);
            queryParams.append("param3", Colorpicker[2]);
            queryParams.append("param4", Colorpicker[3]);
            queryParams.append("param5", selectedFont);
            queryParams.append("param6", currencySymbol);
            if (userdata?.template) {
              queryParams.append("param7", userdata?.template);
            }
            if (userdata?.invoiceType) {
              queryParams.append("param8", userdata?.invoiceType);
            }
            if (response?.payload?.invoice_id) {

              const totalAmount = formData.toatalAmount;
              const roundedtotalAmount = Math.round(totalAmount);
              const totalAmountInWords = Number.isFinite(roundedtotalAmount)
                ? toWords(roundedtotalAmount)
                : "Invalid amount";
              const totalAmountInWordsPrint = totalAmountInWords + " Only.";
              formData.totalInWords = totalAmountInWordsPrint;

              fetch(
                `/api/send_email_Sales_Return_pdf/${response?.payload?.invoice_id
                }?${queryParams.toString()}`,
              )
                .then((response) => {
                  if (!response.ok) {
                    throw new Error("Network response was not ok");
                  }
                  return response.blob();
                })
                .then((blob) => {
                  api.post(`/api/salesreturn/upload`, emailViewPdf)
                    .then((pdfResp: any) => {
                      console.log("Blob uploaded successfully:", pdfResp);
                      if (pdfResp.data.message) {
                        toast.success("Email Send Successfully", {
                          position: toast.POSITION.BOTTOM_CENTER,
                          autoClose: 800,
                        });
                      }
                    })
                    .catch((error: any) => {
                      console.error("Error uploading blob:", error);
                    });
                })
                .catch((error) => console.error("Error downloading PDF:", error));
            }
          });
        }
      }
      else if (id || invoiceUpdateId) {
        let invoiceId = id ? id : invoiceUpdateId;
        formData.invoicedate = formatDate;
        formData.product = getProductCal(formData);
        formData?.product.forEach((item: any) => {
          item.amount = Number(item.amount).toFixed(2);
        });
        dispatch(SalesReturnUpdate(invoiceId, formData) as any).then(
          (response: any) => {
            const queryParams = new URLSearchParams();
            queryParams.append("param1", Colorpicker[0]);
            queryParams.append("param2", Colorpicker[1]);
            queryParams.append("param3", Colorpicker[2]);
            queryParams.append("param4", Colorpicker[3]);
            if (formData.colorTemplateType) {
              queryParams.append("param5", formData.colorTemplateType);
            }
            queryParams.append("param6", selectedFont);
            queryParams.append("param7", currencySymbol);
            if (formData.invoiceTemplateType) {
              queryParams.append("param8", formData.invoiceTemplateType);
            }

            const totalAmount = formData.toatalAmount;
            const roundedtotalAmount = Math.round(totalAmount);
            const totalAmountInWords = Number.isFinite(roundedtotalAmount)
              ? toWords(roundedtotalAmount)
              : "Invalid amount";
            const totalAmountInWordsPrint = totalAmountInWords + " Only";
            formData.totalInWords = totalAmountInWordsPrint;

            // Construct the URL with the query parameters

            fetch(
              `/api/send_email_Sales_Return_pdf/${invoiceId
              }?${queryParams.toString()}`,
            )
              .then((response) => {
                if (!response.ok) {
                  throw new Error("Network response was not ok");
                }
                return response.blob();
              })
              .then((blob) => {
                api.post(`/api/salesreturn/upload`, emailViewPdf)
                  .then((pdfResp: any) => {
                    console.log("Blob uploaded successfully:", pdfResp);
                    if (pdfResp.data.message) {
                      toast.success("Email Send Successfully", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 800,
                      });
                    }
                  })
                  .catch((error: any) => {
                    console.error("Error uploading blob:", error);
                  });
              })
              .catch((error) => console.error("Error downloading PDF:", error));
          },
        );
      }

      setOpenMail(false);
      setErrorEmail(null);
      setOpenView(false);
      setFormData({
        ...formData,
        To: "",
        Subject: "",
        Message: "",
        errors: { ...formData.errors, ToErrorMessage: "" },
      });
    } else {
      if (formData.To?.trim() === "") {
        sendEmailErrors["To"] = "Email is requried  ";
        setErrorEmail(sendEmailErrors);
      }
    }
  };

  // *********************************************EMail Sctions End**************************************
  const [editValue, setEditValue] = useState(false as any);

  useEffect(() => {
    if (!id) {
      fetchData();
      formData.billTaxType = userdata?.taxType
      // formData.gstType = userdata?.gstValue
      formData.invoiceTemplateType = userdata?.invoiceType
      formData.colorTemplateType = userdata?.template
    } else {
      // if (invoiceModule == "PRODUCT") {
      dispatch(salesReturnFindOne(id) as any).then((response: any) => {
        let accountSettings = JSON.parse(response?.payload?.app_settings);
        // setTaxAllList(accountSettings);
        let invoiceDetails = JSON.parse(response?.payload?.products);
        setInvoiceDetails(invoiceDetails)
        let products = invoiceDetails.product;
        if (id) {
          setEditValue(true);
          let newTaxStatus: any = [];
          let newTaxArr: any = [];
          for (let o in accountSettings[0]) {
            if (accountSettings[0][o] === true) {
              newTaxStatus.push(o);
            }
          }
          let finalTotal = invoiceDetails.subtotal;
          newTaxStatus.forEach((element: string) => {
            if (newTaxStatus.includes("discountStatus")) {
              let taxAmt =
                (finalTotal * Number(accountSettings[0].discountPercentage)) /
                100;
              newTaxArr.push({
                taxName: "Discount",
                cgstStatus: accountSettings[0].discountStatus,
                taxAmount: taxAmt.toFixed(2).toString(),
                taxPersent: accountSettings[0].discountPercentage,
              });
              finalTotal -= taxAmt;
            }
            if (element === "cgstStatus") {
              let taxAmt =
                (invoiceDetails.subtotal *
                  Number(accountSettings[0].cgstPercentage)) /
                100;
              newTaxArr.push({
                taxName: "CGST",
                cgstStatus: accountSettings[0].cgstStatus,
                taxAmount: taxAmt.toFixed(2).toString(),
                taxPersent: accountSettings[0].cgstPercentage,
              });
              finalTotal = Number(finalTotal) + Number(taxAmt);
            } else if (element === "sgstStatus") {
              let taxAmt =
                (invoiceDetails.subtotal *
                  Number(accountSettings[0].sgstPercentage)) /
                100;
              newTaxArr.push({
                taxName: "SGST",
                cgstStatus: accountSettings[0].sgstStatus,
                taxAmount: taxAmt.toFixed(2).toString(),
                taxPersent: accountSettings[0].sgstPercentage,
              });
              finalTotal = Number(finalTotal) + Number(taxAmt);
            } else if (element === "extraFeesStatus") {
              newTaxArr.push({
                taxName: "Extra Fees",
                cgstStatus: accountSettings[0].extraFeesStatus,
                taxAmount: accountSettings[0].extraFees,
                taxPersent: accountSettings[0].extraFees,
              });
              finalTotal =
                Number(finalTotal) + Number(accountSettings[0].extraFees);
            } else if (element === "igstStatus") {
              let taxAmt =
                (invoiceDetails.subtotal *
                  Number(accountSettings[0].igstPercentage)) /
                100;
              newTaxArr.push({
                taxName: "IGST",
                cgstStatus: accountSettings[0].igstStatus,
                taxAmount: taxAmt.toFixed(2).toString(),
                taxPersent: accountSettings[0].igstPercentage,
              });
              finalTotal = Number(finalTotal) + Number(taxAmt);
            } else if (element === "vatStatus") {
              let taxAmt =
                (invoiceDetails.subtotal *
                  Number(accountSettings[0].vatPercentage)) /
                100;
              newTaxArr.push({
                taxName: "VAT",
                cgstStatus: accountSettings[0].vatStatus,
                taxAmount: taxAmt.toFixed(2).toString(),
                taxPersent: accountSettings[0].vatPercentage,
              });
              finalTotal = Number(finalTotal) + Number(taxAmt);
            } else if (element === "othersStatus") {
              let taxAmt =
                (invoiceDetails.subtotal *
                  Number(accountSettings[0].othersPercentage)) /
                100;
              newTaxArr.push({
                taxName: "OTHERS",
                cgstStatus: accountSettings[0].othersStatus,
                taxAmount: taxAmt.toFixed(2).toString(),
                taxPersent: accountSettings[0].othersPercentage,
              });
              finalTotal = Number(finalTotal) + Number(taxAmt);
            }
          });

          let isSendEmail = invoiceDetails.isSendEmail ? true : true;
          let billTaxType = invoiceDetails.billTaxType;
          let invoiceTemplateType = invoiceDetails.invoiceTemplateType;
          let colorTemplateType = invoiceDetails.colorTemplateType;
          // let termsanddconvalue = invoiceDetails.termsanddcon
          setFormData({
            ...invoiceDetails,
            tax: invoiceDetails.tax,
            toatalAmount: finalTotal,
            isSendEmail: isSendEmail,
            billTaxType:invoiceDetails?.billTaxType,
            gstType:invoiceDetails?.gstValue,
            invoiceTemplateType: invoiceTemplateType,
            colorTemplateType: colorTemplateType,
            invoiceNo: invoiceDetails.invoiceNo,
            SalesreturnNo: invoiceDetails.SalesreturnNo,
            salesReturnDate: invoiceDetails.salesReturnDate,
            invoicedate: formatDate,
            duedate: addValue ? moment(dueDate).format("DD/MM/YYYY") : "",
            imageData: "",
            companyName: invoiceDetails.companyName,
            companyAddress: invoiceDetails.companyAddress,
            companyCity: invoiceDetails.companyCity,
            companyState: invoiceDetails.companyState,
            companyPostalCode: invoiceDetails.companyPostalCode,
            companyMobile: invoiceDetails.companyMobile,
            companyEmail: invoiceDetails.companyEmail,
            companyPan: invoiceDetails.companyPan,
            companygst: invoiceDetails.companygst,
            clientName: invoiceDetails.clientName,
            clientMobile: invoiceDetails.clientMobile,
            clientEmail: invoiceDetails.clientEmail,
            clientPan: invoiceDetails.clientPan,
            subtotal: invoiceDetails.subtotal,
            // product: invoiceDetails.product,
            extrafees: [],
            user_id: token?._id,
            To: "",
            Subject: "",
            nontaxableTotal: invoiceDetails.nontaxableTotal,
            totalInWords: "",
            clientId: invoiceDetails.clientId,
            paymentType: invoiceDetails.paymentType,
            receivedAmount: invoiceDetails.receivedAmount,
            // termsanddcon: termsanddconvalue
          });

          setbillModule("PRODUCT")

          let finalErrors: any = [];
          let value = products?.map((item: any, index: any) => {
            let object: any = {};
            object.prodcut = item.prodcut ? true : false;
            object.rate = item.rate ? true : false;
            object.qty = item.qty ? true : false;
            finalErrors.push(object);
          });
        }
      });
      // }
    }
  }, [id]);

  const closeModal = () => {
    setErrorEmail(null);
    setOpenView(false);
    setOpenMail(false);
    setFormData({
      ...formData,
      To: "",
      Subject: "",
      Message: "",
      errors: { ...formData.errors, ToErrorMessage: "" },
    });
  };

  const [viewOpen, setOpenView] = useState(false);
  const cancelButtonRef = useRef(null);
  const componentRef = useRef(null);

  // const [preViewdDis, setPreViewDis] = useState(true)

  const openChoosePreview = () => {
    if (formData.clientName && formData.invoiceNo) {
      setOpenView(true);
    } else {
      validateProdForm();
    }
  };

  const handlePrint = useReactToPrint({
    content: () => getPrintContent(),
    documentTitle: "Invoice Letter",
    onAfterPrint: () => {
      console.log("Print completed!");
    },
  });

  const getPrintContent = (): any => {
    if (formData.clientName) {
      return componentRef.current;
    } else {
      validateProdForm();
    }
  };
  const handleCancel = () => {
    setConfirmDownload(false);
  };
  const User_id = sessionStorage.getItem("userInfo");
  const userId = JSON.parse(User_id as any);

  const fetchDatas = () => {
    dispatch(registerAll(userId?.token?._id) as any).then((res: any) => {
      if (res && res.type === "REGISTER_GET_SUCCESS") {
        setClientList(res?.payload?.color ? res?.payload?.color : "#000000");
        setbillModule(res?.payload?.billModule ? res?.payload?.billModule : "PRODUCT");
      }
    });
  };

  useEffect(() => {
    if (userId?.token?._id) {
      fetchDatas();
    }
  }, []);

  const [returnProducts, setReturnProducts] = useState([] as any)

  return (
    <>
      <div>
        {(formData?.billTaxType == "Bill Wise" ) ? (
          <>
            {" "}
            <div style={{ display: "none" }}>
              <Print
                Invoice={formData}
                componentRef={componentRef} />
            </div>
          </>
        ) : (
          <>
              <div style={{ display: "none" }}>
                <PrintProductwise
                  Invoice={formData}
                  componentRef={componentRef}
                />
              </div>
          </>
        )}
        {billModules !== 'PRODUCT' ? (
          <>
            <div style={{ display: "none" }}>
              <PrintService ServiceInvoice={formData} componentRef={componentRef} />
            </div>
          </>
        ) : ""}
      </div>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-10/12 pl-4 pr-4 sm:pl-4 sm:pr-0 mb-4 sm:mb-1 margin  items-start bg-[white] min-h-screen pb-5">
          <form>
            <div className="bg-[white] sticky z-10 top-0 left-0 right-0 pb-2 ">
              <div className="justify-center flex flex-row relative" style={{ fontSize: '28px', fontWeight: '600' }}>
                SALES RETURN
                {/* </h1> */}
              </div>

            </div>
            <hr className="mx-8" style={{ borderColor: "#008065" }} />
            <div className="grid grid-cols-6 overflow-x-auto">
              <div className="col-start-1 col-end-2">
              </div>

              <div className="col-start-2 col-span-4 ">
                {formData.companyName ? (
                  <div className=" text-center  pt-2 text-sm font-[1500] sm:font-normal sm:text-lg " style={{
                    fontWeight: 800
                  }}>
                    {formData.companyName}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="flex flex-col justify-center items-center pb-2">
              <div className="capitalize text-center">
                <div className="mt-1">
                  <span>
                    {formData.companyAddress ? (
                      <span>Address: {formData.companyAddress},</span>
                    ) : null}
                    {formData.companyCity ? <span>{formData.companyCity},</span> : null}
                    {formData.companyState ? (
                      <span>{formData.companyState}-</span>
                    ) : null}
                    {formData.companyPostalCode ? (
                      <span>{formData.companyPostalCode},</span>
                    ) : null}
                    {formData.companygst ? (
                      <span>
                        {" "}
                        Gstn:
                        {formData.companygst}
                      </span>
                    ) : null}
                  </span>
                </div>
                <div>
                  <span>
                    {formData.companyPan ? (
                      <span>PAN: {formData.companyPan},</span>
                    ) : null}
                    {formData.companyEmail ? (
                      <span>
                        Email:{" "}
                        <span className="lowercase">{formData.companyEmail},</span>
                      </span>
                    ) : null}{" "}
                    {formData.companyMobile ? (
                      <span>Mobile: {formData.companyMobile},</span>
                    ) : null}
                  </span>
                </div>
              </div>
            </div>

            <hr className="mx-8" style={{ borderColor: "#008065" }} />

            <div >
              <ClientDetailsReturn
                setNewexistProduct={setNewexistProduct}
                newExistProduct={newExistProduct}
                selectedClientData={selectedClientData}
                setSelectedClientData={setSelectedClientData}
                handleChange={handleChange}
                formData={formData}
                clientErrors={clientErrors}
                setFormData={setFormData}
                paramsId={id}
                invoiceDate={invoiceDate}
                setInvoiceDate={setInvoiceDate}
                billModules={billModules}
                addValue={addValue}
                dueDate={dueDate}
                setReturnProducts={setReturnProducts}
                prodcutRows={prodcutRows}
                TaxAllList={TaxAllList}
                setTaxAllList={setTaxAllList}
                setProductRows={setProductRows}
                setDueDate={setDueDate}
                handleAddvalue={handleAddvalue}
                handleRemoveDate={handleRemoveDate}
                salesReturnDate={invoiceDate}
                setSalesReturnDate={setSalesReturnDate}
              />
            </div>


            <hr className="mr-8 ml-4 mt-2" style={{ borderColor: "#008065" }} />
            <div className="py-2 px-4">
              {(formData.billTaxType == "Product Wise") ? (
                <>
                  <SalesReturnNewProducts
                    setCashAmount={setCashAmount}
                    setbalanceAmount={setbalanceAmount}
                    setSelectedPayment={setSelectedPayment}
                    formData={formData}
                    setFormData={setFormData}
                    TaxAllList={TaxAllList}
                    paramsId={id}
                    returnProducts={returnProducts}
                    prodcutRows={prodcutRows}
                    setProductRows={setProductRows}
                    addRow={addRow}
                    editValue={editValue}
                    setNewexistProduct={setNewexistProduct}
                    newExistProduct={newExistProduct}
                  />
                </>
              ) : (
                <>
                  {(
                    <SalesReturnProducts
                      setCashAmount={setCashAmount}
                      setbalanceAmount={setbalanceAmount}
                      setSelectedPayment={setSelectedPayment}

                      setNewexistProduct={setNewexistProduct}
                      newExistProduct={newExistProduct}
                      formData={formData}
                      setFormData={setFormData}
                      TaxAllList={TaxAllList}
                      paramsId={id}
                      returnProducts={returnProducts}
                      prodcutRows={prodcutRows}
                      setProductRows={setProductRows}
                      addRow={addRow}
                      editValue={editValue}
                    />
                  )}
                </>
              )}

              <div className={"flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center"}>
                <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"}>
                  SUBTOTAL
                </div>
                <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"font-title w-1/2 sm:w-1/4 text-right sm:pr-9 flex flex-row justify-end sm:block mb-1 whitespace-nowrap"}>
                  <NumberFormat value={formData.subtotal ? formData.subtotal : 0} />
                </div>
              </div>
              {formData.billTaxType == "Product Wise" ? (
                <></>
              ) : (
                <>
                  <SalesGstSection TaxAllList={TaxAllList} formData={formData} returnProducts={returnProducts} />
                </>
              )}


              <div className={"flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"}>
                <div className={"w-full sm:w-1/2 px-4 py-1 flex flex-row rounded-lg items-center"}>
                  <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"}>
                    TOTAL
                  </div>
                  <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"}>
                    <NumberFormat value={formData?.toatalAmount} />
                  </div>
                </div>
              </div>
              <div
                className={
                  "flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
                }
              >
                <div
                  className={
                    "w-full sm:w-1/2 px-4 py-1 flex flex-row rounded-lg items-center"
                  }
                >
                  <div
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className={
                      "w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
                    }
                  >
                    PAYMENT TYPE
                  </div>
                  <div
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className={
                      "font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
                    }
                  >
                    <select
                      name="paymentType"
                      id="paymentType"
                      className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 lg:w-full"
                      style={{
                        fontFamily: "poppins",
                        fontSize: "13px",
                        fontWeight: 400,
                        borderColor: clientErrors.paymentType ? "red" : "#008065",
                      }}
                      value={id ? formData.paymentType : selectedPayment}
                      onChange={(e: any) => handleChange(e)}
                      required
                    >
                      {id ? (
                        <>
                          <option value={formData.paymentType}>
                            {id ? formData.paymentType : selectedPayment}
                          </option>
                        </>
                      ) : (
                        <>
                          <option value="" disabled>
                            PAYMENT TYPE
                          </option>
                          <option value="CASH">
                            CASH
                          </option>
                          {billModules === "PRODUCT" && (
                            <>
                              <option value="CREDIT">CREDIT</option>
                              <option value="CHEQUE">CHEQUE</option>
                              {/* <option value="CARD">CARD</option>
                      <option value="UPI">UPI</option>
                      <option value="EMI">EMI</option> */}
                            </>)}
                        </>
                      )}

                    </select>
                    {clientErrors.paymentType && (
                      <p
                        style={{
                          fontFamily: "poppins",
                          fontWeight: 700,
                          color: "#ff0000",
                        }}
                        className="text-red-400 text-xs"
                      >
                        {clientErrors.paymentType}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {id && (
                <div className="flex flex-row sm:justify-end w-full items-center text-black">
                  <div className="w-full sm:w-1/2 px-4 py-1 flex flex-row rounded-lg items-center">
                    <div
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className="w-1/2 text-right sm:pr-9 flex sm:block items-center"
                    >
                      RECEIVED AMOUNT
                    </div>
                    <div
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className="font-title text-lg w-1/2 text-right sm:pr-9 flex justify-end sm:block items-center whitespace-nowrap"
                    >
                      <input
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: "#00A787",
                        }}
                        readOnly
                        autoComplete="off"
                        name="receivedAmount"
                        value={id ? formData.receivedAmount : 0.00}
                        onChange={handleChange}
                        placeholder="0.00"
                        type="number"
                        className={
                          defaultInputSmStyle +
                          " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                        }
                      />
                    </div>
                  </div>
                </div>
              )}

              {selectedPayment === "CASH" && (
                <div
                  className={
                    "flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
                  }
                >
                  <div
                    className={
                      "w-full sm:w-1/2 px-4 py-1 flex flex-row rounded-lg items-center"
                    }
                  >
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={
                        "w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
                      }
                    >
                      CASH AMOUNT
                    </div>
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={
                        "font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
                      }
                    >
                      <input
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: fieldErrors.cashAmount ? "red" : "#00A787",
                        }}
                        readOnly={billModules === "SERVICE"}
                        name="cashAmount"
                        value={CashAmount}
                        min="1"
                        autoComplete="nope"
                        onChange={(e) => {

                          handleChange(e);

                        }}

                        placeholder="0.00"
                        type="Number"

                        className={
                          defaultInputSmStyle +
                          " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
                        }

                      />
                      {fieldErrors.cashAmount ? (
                        <span
                          style={{
                            fontFamily: "poppins",
                            fontWeight: 700,
                            color: "#ff0000",
                          }}
                          className="text-red-400 text-xs"
                        >
                          {fieldErrors.cashAmount}
                        </span>
                      ) : errors.cashAmount ? (
                        <span
                          style={{
                            fontFamily: "poppins",
                            fontWeight: 700,
                            color: "#ff0000",
                          }}
                          className="text-red-400 text-xs"
                        >
                          {errors.cashAmount}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>

                  </div>
                </div>
              )}
             
              {selectedPayment === "CHEQUE" && (
                <div
                  className={
                    "flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
                  }
                >
                  
                </div>
              )}
              {selectedPayment === "UPI" && (
                <div
                  className={
                    "flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
                  }
                >
                  <div
                    className={
                      "w-full sm:w-1/2 px-4 py-1 flex flex-row rounded-lg items-center"
                    }
                  >
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={
                        "w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
                      }
                    >
                      UPI AMOUNT
                    </div>
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={
                        "font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
                      }
                    >
                      <input
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: "#00A787",
                        }}
                        autoComplete="nope"
                        value={formData?.toatalAmount}
                        placeholder="0.00"
                        type="Number"
                        className={
                          defaultInputSmStyle +
                          " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
              {selectedPayment === "NETBANKING" && (
                <div
                  className={
                    "flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
                  }
                >
                  <div
                    className={
                      "w-full sm:w-1/2 px-4 py-1 flex flex-row rounded-lg items-center"
                    }
                  >
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={
                        "w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
                      }
                    >
                      NET BANKING AMOUNT
                    </div>
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={
                        "font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
                      }
                    >
                      <input
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: "#00A787",
                        }}
                        autoComplete="nope"
                        value={formData?.toatalAmount}
                        placeholder="0.00"
                        type="Number"
                        className={
                          defaultInputSmStyle +
                          " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
              {selectedPayment === "EMI" && (
                <div
                  className={
                    "flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
                  }
                >
                  <div
                    className={
                      "w-full sm:w-1/2 px-4 py-1 flex flex-row rounded-lg items-center"
                    }
                  >
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={
                        "w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
                      }
                    >
                      EMI AMOUNT
                    </div>
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={
                        "font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
                      }
                    >
                      <input
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: "#00A787",
                        }}
                        autoComplete="nope"
                        value={formData?.toatalAmount}
                        placeholder="0.00"
                        type="Number"
                        className={
                          defaultInputSmStyle +
                          " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
                        }
                      />
                    </div>
                  </div>
                </div>
              )}

              <div
                className={
                  "flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
                }
              >
                <div
                  className={
                    "w-full sm:w-1/2 px-4 py-1 flex flex-row rounded-lg items-center"
                  }
                >
                  <div
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className={
                      "w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
                    }
                  >
                    BALANCE AMOUNT
                  </div>

                  <div
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className={
                      "font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
                    }
                  >
                    <input
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        borderColor: "#00A787",
                      }}
                      value={balanceAmount}
                      // name="balanceAmt"
                      autoComplete="nope"
                      placeholder="0.00"
                      type=""
                      className={
                        defaultInputSmStyle +
                        " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
                      }
                    />
                  </div>


                </div>
              </div>
             
            </div>
          </form>
        </div>
        <div className="sticky top-[12px] right-0 w-full lg:w-2/12 pl-2 pr-4 sm:pr-2 mt-3 mx-4 md:mx-0 self-start xl:pb-20 sm:pb-40 xs:pb-40">
          <div style={{ fontFamily: "poppins", fontSize: "14px" }}>
            <button
              type="button"
              className="rounded-xl font-bold text-white flex flex-row items-center justify-center text-sm sm:text-sm md:text-sm lg:text-xs xl:text-sm 2xl:text-sm h-8 px-2  block w-full "
              style={{ backgroundColor: "#008065" }}
              onClick={openChoosePreview}
            >
              Pre View
            </button>
          </div>
          <div
            style={{ fontFamily: "poppins", fontSize: "14px" }}
            className="mt-2"
          >
            <button
              className="rounded-xl font-bold text-white flex flex-row items-center justify-center text-sm sm:text-sm md:text-sm lg:text-xs xl:text-sm 2xl:text-sm h-8 px-2  block w-full "
              style={{ backgroundColor: "#008065" }}
              onClick={handlePrint}
            >
              Print
            </button>
          </div>
          <div
            style={{ fontFamily: "poppins", fontSize: "14px" }}
            className=" w-full text-right mt-2 "
          >
            <button
              // size="sm"
              type="submit"
              className="rounded-xl font-bold text-white  flex flex-row-reverse flex-row items-center justify-center text-sm sm:text-sm md:text-sm lg:text-xs xl:text-sm 2xl:text-sm h-8 px-2  block w-full "
              style={{ backgroundColor: "#008065" }}
              onClick={(e: any) => handleSubmit(e)}
            >
              <svg
                className="pr-2 text-white"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 20 20"
                fill="white"
              >
                <mask
                  id="mask0_278_27"
                  style={{ maskType: "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                >
                  <rect width="20" height="20" fill="white" />
                </mask>
                <g mask="url(#mask0_278_27)">
                  <path
                    d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V15H6V18H18V15H20V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H6Z"
                    fill="white"
                  />
                </g>
              </svg>
              {/* {params.id !== "new" ? "Update" : "Download"} */}
              Download
            </button>
          </div>
          <div
            style={{ fontFamily: "poppins", fontSize: "14px" }}
            className=" w-full text-right mt-2 "
          >
            <button
              type="button"
              className="rounded-xl font-bold text-white  flex flex-row-reverse  items-center justify-center text-sm sm:text-sm md:text-sm lg:text-xs xl:text-sm 2xl:text-sm h-8 px-2  block w-full whitespace-nowrap"
              style={{ backgroundColor: "#008065" }}
              onClick={(e: any) => handleSectionOpen(e)}
            >
              <svg
                className="pl-2"
                width="30"
                height="30"
                viewBox="0 0 20 20"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_278_36"
                  style={{ maskType: "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="23"
                  height="23"
                >
                  <rect
                    y="12.0752"
                    width="16"
                    height="16"
                    transform="rotate(-49 0 12.0752)"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_278_36)">
                  <path
                    d="M11.3749 19.3134L3.32462 12.3154L15.6598 6.25476L11.3749 19.3134ZM10.7402 16.995L13.4073 8.84593L5.70878 12.6213L7.46977 14.1521L10.8487 11.7893L8.97919 15.4642L10.7402 16.995Z"
                    fill="white"
                  />
                </g>
              </svg>
              Send Email
            </button>
          </div>

          {/* <div
            style={{ fontFamily: "poppins", fontSize: "14px" }}
            className=" w-full  mt-2 sm:mb-auto mb-16"
          >
            <ColorScheme
              clientList={clientList}
              handleColorChange={handleColorChange}
              setColorPicker={setColorPicker}
              ColourEmpty={ColourEmpty}
            />
          </div> */}
        </div>
      </div>

      {openmail && (
        <Transition.Root show={openmail} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={setOpenMail}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <SendEmailModel
                      closeModal={closeModal}
                      handleChange={handleChange}
                      formData={formData}
                      setFormData={setFormData}
                      handleSendEmail={handleSendEmail}
                      sendEmailErrors={sendEmailErrors}
                      errorEmail={errorEmail}
                      setErrorEmail={setErrorEmail}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
      {confirmDownload && (
        <ConfirmationDownload
          onDownload={downloadInvoice}
          onSaveDownload={saveAndDownloadInvoice}
          onCancel={handleCancel}
        />
      )}
      <Transition.Root show={viewOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOpenView}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:mx-8 lg:w-10/12 md:w-11/12 ">
                  {(formData?.billTaxType == "Bill Wise") ? (
                    <Preview
                      Invoice={formData}
                      componentRef={componentRef}
                      closeModal={closeModal}
                      selectedFont={selectedFont}
                    />
                  ) : (
                    <> (
                      <PreviewNewProductwise
                        Invoice={formData}
                        componentRef={componentRef}
                        closeModal={closeModal}
                        selectedFont={selectedFont}
                      />
                      )
                    </>
                  )}

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default InvoicePage;

